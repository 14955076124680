import cn from 'classnames';
import styles from './NavbarButton.module.scss';

type NavbarButtonProps = {
  className?: string;
  img: string;
  text: string;
  onClick: () => void;
};

export function NavbarButton({
  className,
  img,
  text,
  onClick,
}: NavbarButtonProps) {
  const buttonClassName = cn(styles.button, className);

  return (
    <div className={buttonClassName} onClick={onClick}>
      <img loading="lazy" width={24} height={24} src={img} alt={text} />
      <span className={styles.text}>{text}</span>
    </div>
  );
}
