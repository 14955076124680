/* eslint-disable no-console */

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const pixelRunScript = (_goal: string) => {
  return;
  // if (window._tmr) {
  //   window._tmr.push({ type: 'reachGoal', id: 3250487, goal });
  // } else {
  //   console.error('Ошибка: объект _tmr не найден.');
  // }
};
